AFRAME.registerComponent("vr-teleport", {
    init: function () {
        this.bindMethods();

        this.teleportOkSoundEl = document.getElementById("teleport-ok-sound");
        this.teleportBadSoundEl = document.getElementById("teleport-bad-sound");

        this.cameraRigEl = document.getElementById("camera-rig");

        this.el.addEventListener("raycaster-intersection", this.onIntersectionChange);
        this.el.addEventListener("raycaster-intersection-cleared", this.onIntersectionChange);

        this.el.addEventListener("triggerdown", this.onTrigger);
        this.targetIndicatorEl = this.createTargetIndicator();
    },

    tick: function () {
        const intersectedEls = this.el.components.raycaster.intersectedEls;

        if (intersectedEls.length == 0) {
            return;
        }

        if (this.isTeleportable(intersectedEls[0])) {
            const intersection = this.el.components.raycaster.getIntersection(intersectedEls[0]);

            this.targetIndicatorEl.object3D.position.set(
                intersection.point.x,
                intersection.point.y,
                intersection.point.z
            );
        }
    },

    onIntersectionChange: function () {
        const intersectedEls = this.el.components.raycaster.intersectedEls;

        // no intersection
        if (intersectedEls.length == 0) {
            this.targetIndicatorEl.setAttribute("visible", "false");
            this.el.setAttribute("raycaster", "lineColor", "red");
        }
        // intesect teleport plane
        else if (this.isTeleportable(intersectedEls[0])) {
            this.targetIndicatorEl.setAttribute("visible", "true");
            this.el.setAttribute("raycaster", "lineColor", "blue");
        }
        // intersect GUI
        else if (this.isGUI(intersectedEls[0])) {
            this.targetIndicatorEl.setAttribute("visible", "false");
            this.el.setAttribute("raycaster", "lineColor", "green");
        } else {
            this.targetIndicatorEl.setAttribute("visible", "false");
            this.el.setAttribute("raycaster", "lineColor", "green");
        }
    },

    onTrigger: function (evt) {
        const intersectedEls = this.el.components.raycaster.intersectedEls;

        if (intersectedEls.length == 0) this.playTeleportBadSound();

        if (this.isTeleportable(intersectedEls[0])) {
            this.playTeleportOkSound();

            this.cameraRigEl.object3D.position.copy(this.targetIndicatorEl.object3D.position);
        }

        if (this.isStoreItemBb(intersectedEls[0])) {
            this.playSelectItemSound(intersectedEls[0]);
        }
    },

    createTargetIndicator: function () {
        const indicatorEl = document.createElement("a-entity");

        indicatorEl.setAttribute("position", "0 0 0");
        indicatorEl.setAttribute("rotation", "0 0 0");
        indicatorEl.setAttribute("visible", "false");

        indicatorEl.setAttribute("id", "teleport-indicator");
        indicatorEl.setAttribute("obj-model", "obj", "#teleport-indicator-obj");

        indicatorEl.setAttribute("material", {shader: "flat", side: "double", color: "#2870ec"});

        indicatorEl.setAttribute("animation", {
            property: "scale",
            to: "1.2 1.2 1.2",
            dur: "1500",
            easing: "easeInOutQuad",
            loop: "true",
            dir: "alternate",
        });

        this.el.sceneEl.appendChild(indicatorEl);
        return indicatorEl;
    },

    isTeleportable: function (el) {
        return el.classList.contains("teleportable");
    },
    isGUI: function (el) {
        return el.hasAttribute("gui-interactable");
    },
    isStoreItemBb: function (el) {
        console.log(el);
        return el.classList.contains("item-bb");
    },
    playTeleportOkSound: function () {
        this.teleportOkSoundEl.components.sound.playSound();
    },
    playTeleportBadSound: function () {
        this.teleportBadSoundEl.components.sound.playSound();
    },
    playSelectItemSound: function (storeItemEl) {
        storeItemEl.querySelector(".selected-item-sound").components.sound.playSound();
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {
        this.onIntersectionChange = this.onIntersectionChange.bind(this);
        this.onTrigger = this.onTrigger.bind(this);
    },
});
